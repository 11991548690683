import axios from 'axios'
import Cookies from 'js-cookie'

export const fetchStructures = async () => {
    return new Promise((resolve, reject) => {
        fetch(`${process.env.REACT_APP_API_URL}/content/structures`).then((response) => {
            response.json().then((data) => {
                resolve(data);
            }).catch((err) => reject(err));
        })
    })
}
export const fetchContent = async () => {
    return new Promise((resolve, reject) => {
        axios.get(`${process.env.REACT_APP_API_URL}/content/list`).then((response) => {
            resolve(response.data);
        }).catch((err) => reject(err));
    })
}
export const getReportAsync = async (filters) => {
    return new Promise((resolve, reject) => {
        axios.post(`${process.env.REACT_APP_API_URL}/content/report`, filters).then((response) => {
            resolve(response.data);
        }).catch((err) => reject(err));
    })
}
export const addStructureAsync = async (structure) => {
    return new Promise((resolve, reject) => {
        axios.post(`${process.env.REACT_APP_API_URL}/content/report`, { structure }).then((response) => {
            resolve(response.data);
        }).catch(err => reject(err));
    })
}

// create new ai content


export const createSession = async (params) => {
    return new Promise((resolve, reject) => {
        let url;
        if (params?.scheduled_time) {
            url = `${process.env.REACT_APP_NEW_CONTENT_CREATION_API_URL}/api/creation/schedule-session`
        } else {
            url = `${process.env.REACT_APP_NEW_CONTENT_CREATION_API_URL}/api/creation/session`
        }
        fetch(url, {
            method: "POST",
            body: JSON.stringify(params),
            headers: {
                "Content-type": "application/json; charset=UTF-8",
                Authorization: "Bearer " + Cookies.get('X-Auth')
            }
        }).then(json => json.json())
            .then(function (response) {
                resolve(response);
            }).catch(function (e) {
                reject(e);
            });

    })
};

export const createSessionBulk = async (file = null, params = {}) => {
    return new Promise((resolve, reject) => {
        const formData = new FormData();
        formData.append('file', file);
        if (params?.scheduled_time) {
            formData.append('scheduled_time', params.scheduled_time);
        }
        fetch(`${process.env.REACT_APP_NEW_CONTENT_CREATION_API_URL}/api/creation/session`, {
            method: "POST",
            body: formData,
            headers: {
                Authorization: "Bearer " + Cookies.get('X-Auth')
            }
        }).then(json => json.json())
            .then(function (response) {
                resolve(response);
            }).catch(function (e) {
                reject(e);
            });
    })
}

export const getSessionTrackersList = async (statuses, page, per_page) => {
    return new Promise((resolve, reject) => {
        fetch(`${process.env.REACT_APP_NEW_CONTENT_CREATION_API_URL}/api/creation/sessions-trackers?statuses=${statuses}&page=${page}&per_page=${per_page}`, {
            method: "GET",
            headers: {
                Authorization: "Bearer " + Cookies.get('X-Auth')
            }
        }).then(json => json.json())
            .then(function (response) {
                resolve(response);
            }).catch(function (e) {
                reject(e);
            });
    })
};

export const getSchContentList = async () => {
    return new Promise((resolve, reject) => {
        fetch(`${process.env.REACT_APP_NEW_CONTENT_CREATION_API_URL}/api/creation/schedule-session-jobs`, {
            method: "GET",
            headers: {
                Authorization: "Bearer " + Cookies.get('X-Auth')
            }
        }).then(json => json.json())
            .then(function (response) {
                resolve(response);
            }).catch(function (e) {
                reject(e);
            });
    })
};


export const getSessionOutlineStats = async (session_id) => {
    return new Promise((resolve, reject) => {
        fetch(`${process.env.REACT_APP_NEW_CONTENT_CREATION_API_URL}/api/creation/session-outline/${session_id}`, {
            method: "GET",
            headers: {
                Authorization: "Bearer " + Cookies.get('X-Auth')
            }
        }).then(json => json.json())
            .then(function (response) {
                resolve(response);
            }).catch(function (e) {
                reject(e);
            });
    })
};
export const getCompetitorOutlineStats = async (session_id) => {
    return new Promise((resolve, reject) => {
        fetch(`${process.env.REACT_APP_NEW_CONTENT_CREATION_API_URL}/api/creation/content-outline/${session_id}`, {
            method: "GET",
            headers: {
                Authorization: "Bearer " + Cookies.get('X-Auth')
            }
        }).then(json => json.json())
            .then(function (response) {
                resolve(response);
            }).catch(function (e) {
                reject(e);
            });
    })
};
export const getSessionOutline = async (session_id) => {
    return new Promise((resolve, reject) => {
        fetch(`${process.env.REACT_APP_NEW_CONTENT_CREATION_API_URL}/api/creation/session-outline/${session_id}/outline`, {
            method: "GET",
            headers: {
                Authorization: "Bearer " + Cookies.get('X-Auth')
            }
        }).then(json => json.json())
            .then(function (response) {
                resolve(response);
            }).catch(function (e) {
                reject(e);
            });
    })
};
export const getSessionsContent = async (session_id) => {
    return new Promise((resolve, reject) => {
        fetch(`${process.env.REACT_APP_NEW_CONTENT_CREATION_API_URL}/api/creation/sessions/${session_id}/content`, {
            method: "GET",
            headers: {
                Authorization: "Bearer " + Cookies.get('X-Auth')
            }
        }).then(json => json.json())
            .then(function (response) {
                resolve(response);
            }).catch(function (e) {
                reject(e);
            });
    })
};

export const reviewOutline = async (session_id, params) => {
    return new Promise((resolve, reject) => {
        fetch(`${process.env.REACT_APP_NEW_CONTENT_CREATION_API_URL}/api/creation/session-outline/${session_id}/review`, {
            method: "PATCH",
            body: JSON.stringify(params),
            headers: {
                "Content-type": "application/json; charset=UTF-8",
                Authorization: "Bearer " + Cookies.get('X-Auth')
            }
        }).then(json => json.json())
            .then(function (response) {
                resolve(response);
            }).catch(function (e) {
                reject(e);
            });

    })
};
export const acceptOutline = async (session_id, params) => {
    return new Promise((resolve, reject) => {
        fetch(`${process.env.REACT_APP_NEW_CONTENT_CREATION_API_URL}/api/creation/session-outline/${session_id}/acceptance`, {
            method: "POST",
            body: JSON.stringify(params),
            headers: {
                "Content-type": "application/json; charset=UTF-8",
                Authorization: "Bearer " + Cookies.get('X-Auth')
            }
        }).then(json => json.json())
            .then(function (response) {
                resolve(response);
            }).catch(function (e) {
                reject(e);
            });

    })
};

export const trackContent = async (params = {}) => {
    return new Promise((resolve, reject) => {
        let fetchParams = {
            get_all: true,
            page: params?.page || 1,
            per_page: params?.perPage || 10,
        }
        fetch(`${process.env.REACT_APP_NEW_CONTENT_CREATION_API_URL}/api/sessions-trackers/${params?.trackerId}/sessions?` + new URLSearchParams(fetchParams), {
            method: "GET",
            headers: {
                Authorization: "Bearer " + Cookies.get('X-Auth')
            }
        }).then(json => json.json())
            .then(function (response) {
                resolve(response);
            }).catch(function (e) {
                reject(e);
            });
    })
}

export const contentByAiSession = async (aiSessionId) => {
    return new Promise((resolve, reject) => {
        fetch(`${process.env.REACT_APP_NEW_CONTENT_CREATION_API_URL}/api/ai-sessions/${aiSessionId}?get_all=true`, {
            method: "GET",
            headers: {
                Authorization: "Bearer " + Cookies.get('X-Auth')
            }
        }).then(json => json.json())
            .then(function (response) {
                resolve(response);
            }).catch(function (e) {
                reject(e);
            });
    })
}
export const contentCostStats = async (aiSessionId) => {
    return new Promise((resolve, reject) => {
        fetch(`${process.env.REACT_APP_NEW_CONTENT_CREATION_API_URL}/api/session-stats/${aiSessionId}`, {
            method: "GET",
            headers: {
                Authorization: "Bearer " + Cookies.get('X-Auth')
            }
        }).then(json => json.json())
            .then(function (response) {
                resolve(response);
            }).catch(function (e) {
                reject(e);
            });
    })
}


export const bulkContentUpdate = async (file = null, params = {}) => {
    return new Promise((resolve, reject) => {
        const formData = new FormData();
        formData.append('file', file);
        if (params?.scheduled_time) {
            formData.append('scheduled_time', params.scheduled_time);
        }
        formData.append('scheduling_type', params.scheduling_type);
        fetch(`${process.env.REACT_APP_NEW_CONTENT_CREATION_API_URL}/api/contents/bulk`, {
            method: "POST",
            body: formData,
            headers: {
                Authorization: "Bearer " + Cookies.get('X-Auth')
            }
        }).then(json => json.json())
            .then(function (response) {
                console.log({ response })
                resolve(response);
            }).catch(function (e) {
                if (axios.isCancel(e)) {
                    resolve(true);
                }
                reject(e);
            });
    })
}
export const getContentUpdateHisotry = async (params = {}) => {
    return new Promise((resolve, reject) => {
        fetch(`${process.env.REACT_APP_NEW_CONTENT_CREATION_API_URL}/api/sessions-trackers`, {
            method: "POST",
            body: JSON.stringify(params),
            headers: {
                "Content-type": "application/json; charset=UTF-8",
                Authorization: "Bearer " + Cookies.get('X-Auth')
            }
        }).then(json => json.json())
            .then(function (response) {
                resolve(response);
            }).catch(function (e) {
                reject(e);
            });
    })
}
export const trackContentSessionOnly = async (params = {}) => {
    return new Promise((resolve, reject) => {
        let fetchParams = {
            page: params?.page || 1,
            per_page: params?.perPage || 10,
        }
        fetch(`${process.env.REACT_APP_NEW_CONTENT_CREATION_API_URL}/api/sessions-trackers/${params?.trackerId}/sessions-only?` + new URLSearchParams(fetchParams), {
            method: "GET",
            headers: {
                Authorization: "Bearer " + Cookies.get('X-Auth')
            }
        }).then(json => json.json())
            .then(function (response) {
                resolve(response);
            }).catch(function (e) {
                reject(e);
            });
    })
}

export const contentTimeStats = async (aiSessionId) => {
    return new Promise((resolve, reject) => {
        fetch(`${process.env.REACT_APP_NEW_CONTENT_CREATION_API_URL}/api/time-stats/${aiSessionId}`, {
            method: "GET",
            headers: {
                Authorization: "Bearer " + Cookies.get('X-Auth')
            }
        }).then(json => json.json())
            .then(function (response) {
                resolve(response);
            }).catch(function (e) {
                reject(e);
            });
    })
}

export const regenerateContent = async (params = {}) => {
    return new Promise((resolve, reject) => {
        fetch(`${process.env.REACT_APP_NEW_CONTENT_CREATION_API_URL}/api/contents/regenerate`, {
            method: "PATCH",
            body: JSON.stringify(params),
            headers: {
                "Content-type": "application/json; charset=UTF-8",
                Authorization: "Bearer " + Cookies.get('X-Auth')
            }
        }).then(json => json.json())
            .then(function (response) {
                resolve(response);
            }).catch(function (e) {
                reject(e);
            });
    })
}

export const getOrignalContent = async (contentType, session_id) => {
    return new Promise((resolve, reject) => {
        fetch(`${process.env.REACT_APP_NEW_CONTENT_CREATION_API_URL}/api/ai-sessions/${session_id}/original-contents?content_type=${contentType}`, {
            method: "GET",
            headers: {
                Authorization: "Bearer " + Cookies.get('X-Auth')
            }
        }).then(json => json.json())
            .then(function (response) {
                resolve(response);
            }).catch(function (e) {
                reject(e);
            });
    })
}

export const enrichContent = async (params) => {
    return new Promise((resolve, reject) => {
        fetch(`${process.env.REACT_APP_NEW_CONTENT_CREATION_API_URL}/api/contents/enrich`, {
            method: "POST",
            body: JSON.stringify(params),
            headers: {
                "Content-type": "application/json; charset=UTF-8",
                Authorization: "Bearer " + Cookies.get('X-Auth')
            }
        }).then(json => json.json())
            .then(function (response) {
                resolve(response);
            }).catch(function (e) {
                reject(e);
            });
    })
}

export const reviewEnrichContent = async (params) => {
    return new Promise((resolve, reject) => {
        fetch(`${process.env.REACT_APP_NEW_CONTENT_CREATION_API_URL}/api/contents/enrich-approval`, {
            method: "POST",
            body: JSON.stringify(params),
            headers: {
                "Content-type": "application/json; charset=UTF-8",
                Authorization: "Bearer " + Cookies.get('X-Auth')
            }
        }).then(json => json.json())
            .then(function (response) {
                resolve(response);
            }).catch(function (e) {
                reject(e);
            });
    })
}

export const generateOptHeading = async (params = {}) => {
    return new Promise((resolve, reject) => {
        fetch(`${process.env.REACT_APP_NEW_CONTENT_CREATION_API_URL}/api/contents/opt-heading`, {
            method: "POST",
            body: JSON.stringify(params),
            headers: {
                "Content-type": "application/json; charset=UTF-8",
                Authorization: "Bearer " + Cookies.get('X-Auth')
            }
        }).then(json => json.json())
            .then(function (response) {
                resolve(response);
            }).catch(function (e) {
                reject(e);
            });
    })
}


export const sideBySideContent = async (aiSessionId) => {
    return new Promise((resolve, reject) => {
        fetch(`${process.env.REACT_APP_NEW_CONTENT_CREATION_API_URL}/api/ai-sessions/${aiSessionId}/side-by-side-view`, {
            method: "GET",
            headers: {
                Authorization: "Bearer " + Cookies.get('X-Auth')
            }
        }).then(json => json.json())
            .then(function (response) {
                resolve(response);
            }).catch(function (e) {
                reject(e);
            });
    })
}
export const fetchBlogStats = async (aiSessionId) => {
    return new Promise((resolve, reject) => {
        fetch(`${process.env.REACT_APP_NEW_CONTENT_CREATION_API_URL}/api/blog-stats/${aiSessionId}`, {
            method: "GET",
            headers: {
                Authorization: "Bearer " + Cookies.get('X-Auth')
            }
        }).then(json => json.json())
            .then(function (response) {
                resolve(response);
            }).catch(function (e) {
                reject(e);
            });
    })
}

export const updateContentFeedback = async (data = {}) => {
    return new Promise((resolve, reject) => {
        axios.post(`${process.env.REACT_APP_API_URL}/ai-content/update-content/feedback`, data)
            .then(resp => {
                resolve(resp)
            })
            .catch(error => {
                reject(error)
            })
    })
}
export const updateContentFeedbackReport = async (params) => {
    return new Promise((resolve, reject) => {
        axios.get(`${process.env.REACT_APP_API_URL}/ai-content/update-content/feedback/report`, {
            params
        })
            .then(resp => {
                resolve(resp.data)
            })
            .catch(error => {
                reject(error)
            })
    })
}