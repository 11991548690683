import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  failed: false,


  compititorLsiList: [],
  compititorLsiListLoading: false,
  updateWorkflowLoading: false,
  createWorkflowFormData: {},

  history: {
    data: null,
    sidebarLoader: false,
    title: '',
    toggleDetailSidebar: false,
    toggleFormSidebar: false,
    records: [],
    recordsLoader: false,
  },
  stats: {
    data: null,
    sidebarLoader: false,
    title: '',
    toggleDetailSidebar: false,
    toggleFormSidebar: false,
    records: [],
    recordsLoader: false,
  },
  onpageseo: {
    data: null,
    sidebarLoader: false,
    title: '',
    toggleDetailSidebar: false,
    toggleFormSidebar: false,
    records: [],
    recordsLoader: false,
  },
};

const ToolSlice = createSlice({
  name: "workflow",
  initialState,
  reducers: {
    request(state) {
      state.loading = true;
    },
    success(state, action) {
      state.trendings = action.payload;
      state.loading = false;
    },

    setCompititorLsiList(state, action) {
      state.compititorLsiList = action.payload;
    },
    setContentSelectedLsiList(state, action) {
      state.compititorLsiList = action.payload;
    },
    setCompititorLsiListLoading(state, action) {
      state.compititorLsiListLoading = action.payload;
    },
    setUpdateWorkflowLoading(state, action) {
      state.updateWorkflowLoading = action.payload;
    },
    setcreateWorkflowFormData(state, action) {
      state.createWorkflowFormData = action.payload;
    },
    setHistory(state, action) {
      state.history = action.payload;
    },
    setStats(state, action) {
      state.stats = action.payload;
    },
    setOnPageSeo(state, action) {
      state.onpageseo = action.payload;
    },



  },
});

export const {
  request,
  success,
  setCompititorLsiList,
  setContentSelectedLsiList,
  setCompititorLsiListLoading,
  setUpdateWorkflowLoading,
  setcreateWorkflowFormData,
  setHistory,
  setStats,
  setOnPageSeo
} = ToolSlice.actions;

export default ToolSlice.reducer;
