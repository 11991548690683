// ** Reducers Imports
import navbar from './navbar'
import layout from './layout'
import auth from './authentication'
import { combineReducers } from 'redux'
import employees from '../views/Employees/EmployeeSlice'
import evaluation from '../views/Employees/EvaluationSlice'
import authorization from '../views/AuthSlice'
import designations from '../views/Employees/DesignationSlice'
import teams from '../views/Panel/slices/TeamSlice'
import affiliate from '../views/Affiliate/AffiliateSlice'
import calendar from '../views/Calendar/CalendarSlice';
import departments from '../views/Departments/slice';
import content from '../views/Content/ContentSlice';
import contentReport from '../views/Content/ContentReport/ReportSlice';
import trending from '../views/Tools/Trending/TrendingSlice';
import aiTool from '../views/Tools/ContentUpdateTool/Store/ToolSlice';
import newAiTool from '../views/ai-content/Store/ToolSlice';
import eeat from '../views/eeat/store/ToolSlice';
import batchProcessTool from '../views/Tools/BatchContentProcess/Store/BatchProcessSlice';
import crawlUrl from '../views/CrawlUrl/CrawlUrlSlice'
import workflow from '../views/Workflow/Store/ToolSlice'
const rootReducer = {
  auth,
  navbar,
  layout,
  employees,
  teams,
  designations,
  authorization,
  evaluation,
  affiliate,
  calendar,
  departments,
  content,
  contentReport,
  trending,
  crawlUrl,
  aiTool,
  newAiTool,
  eeat,
  batchProcessTool,
  workflow
}

export default combineReducers(rootReducer)
